import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import initialState from "./state";
import submissionsModule from "./submissions";
import ruleTriggerGroups from "@/store/modules/rule-trigger-groups";

export default () => {
  return {
    namespaced: true,
    state: () => initialState(),
    getters,
    actions,
    mutations,
    modules: {
      ruleTriggerGroups: ruleTriggerGroups(),
      submissions: submissionsModule
    }
  };
};
